<script setup lang="ts">
definePageMeta({
  allowedAction: 'read',
  allowedDomain: 'products',
})
const { $userCan } = useNuxtApp()
</script>
<template>
  <div class="max-w-full min-h-screen flex flex-col bg-base-200">
    <slot name="header">
      <ClientOnly>
        <Header></Header>
      </ClientOnly>
    </slot>
    <slot name="progress" />
    <ClientOnly>
      <main class="container grid grid-cols-6 gap-4">
        <div class="sidebar mt-5">
          <h4 class="pl-5">Settings</h4>
          <ul class="menu w-full pt-0">
            <li v-if="$userCan('read', 'campaign/type')">
              <NuxtLink to="/settings/campaignType/">Campaign Types</NuxtLink>
            </li>
            <li v-if="$userCan('read', 'products')">
              <NuxtLink to="/settings/jobIntegrations/"
                >Job Integrations</NuxtLink
              >
            </li>
            <li v-if="$userCan('read', 'brand')">
              <NuxtLink to="/settings/brands/">Brands</NuxtLink>
            </li>
            <li v-if="$userCan('read', 'company/payment/history')">
              <NuxtLink to="/">Payment History</NuxtLink>
            </li>
            <li v-if="$userCan('read', 'discounts')">
              <NuxtLink to="/settings/discounts/">Discounts</NuxtLink>
            </li>
            <li v-if="$userCan('read', 'discounts/accountCredits')">
              <NuxtLink to="/settings/credits/">Credits</NuxtLink>
            </li>
          </ul>
        </div>
        <div class="col-span-5">
          <slot />
        </div>
      </main>
    </ClientOnly>
    <slot name="footer">
      <Footer></Footer>
    </slot>
  </div>
</template>
